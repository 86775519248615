import BalanceControllerBase from '../base'
import type { TResponseBase } from '../../types'
import type { NCancelAddonRecurrentSMS } from './types'

export default class AddonRecurrentSMSController extends BalanceControllerBase {
  static readonly controllerPath = 'addonrecurrentsms'
  private readonly serviceUrl: string

  constructor() {
    super(AddonRecurrentSMSController.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async cancelAddonRecurrentSMS(
    organizationId: string,
    addonOrganizationSMSId: string,
  ): TResponseBase<NCancelAddonRecurrentSMS.TResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/${addonOrganizationSMSId}`,
      {
        method: 'DELETE',
      },
    )
  }
}
