import BalanceControllerBase from '../base'
import type { TResponseBase } from '../../types'
import type { NGetInvoices } from './types'

export default class InvoicesController extends BalanceControllerBase {
  static readonly controllerPath = 'invoices'
  private readonly serviceUrl: string

  constructor() {
    super(InvoicesController.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async getInvoices(
    organizationId: string,
    params: NGetInvoices.IParams,
  ): TResponseBase<NGetInvoices.IResponseData> {
    return await useFetchInstance(`${this.serviceUrl}/${organizationId}`, {
      method: 'GET',
      params,
    })
  }
}
