import BalanceControllerBase from '../base'
import type { TResponseBase } from '../../types'
import type { NTransactions } from './types'

export default class TransactionController extends BalanceControllerBase {
  static readonly controllerPath = 'transactions'
  private readonly serviceUrl: string

  constructor() {
    super(TransactionController.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async getTransactions(
    organizationId: string,
    filters: NTransactions.IGetRequestTransactions,
  ): TResponseBase<NTransactions.IGetReponseTransactions> {
    return await useFetchInstance(`${this.serviceUrl}/${organizationId}`, {
      method: 'GET',
      params: filters,
    })
  }

  async exportTransactions(
    organizationId: string,
    filters: NTransactions.IGetRequestExportTransactions,
  ): Promise<Blob> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/export`,
      {
        method: 'GET',
        params: filters,
        responseType: 'blob',
      },
    )
  }

  async getOrganizationCurrentBalance(
    organizationId: string,
  ): TResponseBase<NTransactions.CurrentBalance> {
    return await useFetchInstance(
      `${this.serviceUrl}/currentbalance/${organizationId}`,
      {
        method: 'GET',
      },
    )
  }
}
