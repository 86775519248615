import BalanceControllerBase from '../base'
import type { TResponseBase } from '../../types'
import type { NCancelAddonRecurrentEmail } from './types'

export default class AddonRecurrentEmailController extends BalanceControllerBase {
  static readonly controllerPath = 'addonrecurrentemail'
  private readonly serviceUrl: string

  constructor() {
    super(AddonRecurrentEmailController.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async cancelAddonRecurrentEmail(
    organizationId: string,
    addonOrganizationEmailId: string,
  ): TResponseBase<NCancelAddonRecurrentEmail.TResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/${addonOrganizationEmailId}`,
      {
        method: 'DELETE',
      },
    )
  }
}
