import type { TResponseBase } from '../../types'
import BalanceControllerBase from '../base'
import type { NEditPaymentMethod } from './types'

export default class PaymentMethodController extends BalanceControllerBase {
  static readonly controllerPath = 'paymentMethod'
  private readonly serviceUrl: string

  constructor() {
    super(PaymentMethodController.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async editPaymentMethod(
    organizationId: string,
    setupIntentId: string,
  ): TResponseBase<NEditPaymentMethod.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/edit/${setupIntentId}`,
      {
        method: 'POST',
      },
    )
  }
}
