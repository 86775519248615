import type { TResponseBase } from '../../types'
import BalanceControllerBase from '../base'
import type { NValidateBillingInfo } from './types'

export default class PaymentController extends BalanceControllerBase {
  static readonly controllerPath = 'payment'
  private readonly serviceUrl: string

  constructor() {
    super(PaymentController.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async validateBillingInfo(organizationId: string, body?: NValidateBillingInfo.TBody): TResponseBase<NValidateBillingInfo.IResponseData> {
    return await useFetchInstance(`${this.serviceUrl}/${organizationId}/validatebillinginfo`, {
      method: 'POST',
      body,
    })
  }
}
