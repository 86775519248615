import AddonRecurrentEmailController from './addOnRecurrentEmail'
import AddonRecurrentSMSController from './addOnRecurrentSMS'
import InvoicesController from './invoices'
import OrganizationPlanController from './organizationPlans'
import PaymentController from './payment'
import PaymentMethodController from './paymentMethod'
import PlanCancellationReasonsController from './planCancellationReasons'
import TransactionController from './transactions'

export default class BalanceService {
  public organizationPlan = new OrganizationPlanController()
  public transaction = new TransactionController()
  public invoices = new InvoicesController()
  public planCancellationReasons = new PlanCancellationReasonsController()
  public addOnRecurrentEmail = new AddonRecurrentEmailController()
  public addOnRecurrentSMS = new AddonRecurrentSMSController()
  public payment = new PaymentController()
  public paymentMethod = new PaymentMethodController()
}
