import type { TResponseBase } from '../../types'
import BalanceControllerBase from '../base'
import type { NGetPlanCancellationReasons } from './types'

export default class PlanCancellationReasonsController extends BalanceControllerBase {
  static readonly controllerPath = 'plancancellationreasons'
  private readonly serviceUrl: string

  constructor() {
    super(PlanCancellationReasonsController.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async getPlanCancellationReasons(): TResponseBase<NGetPlanCancellationReasons.TResponseData> {
    return await useFetchInstance(`${this.serviceUrl}`, {
      method: 'GET',
    })
  }
}
