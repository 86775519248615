import BaseService from '../base'

export default class BalanceControllerBase extends BaseService {
  static readonly basePath = 'balance/api/v1.0'
  private readonly controllerPath: string

  constructor(controllerPath: string) {
    super(BalanceControllerBase.basePath)
    this.controllerPath = controllerPath
  }

  protected getFullServiceUrl(): string {
    return `${this.getFullBaseUrl()}/${this.controllerPath}`
  }
}
