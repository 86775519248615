import BalanceControllerBase from '../base'
import type { TResponseBase } from '../../types'
import type {
  NCancelOrganizationPlan,
  NGetOrganizationPlanOverview,
  NOrganizationPlans,
} from './types'

export default class OrganizationPlanController extends BalanceControllerBase {
  static readonly controllerPath = 'organizationplans'
  private readonly serviceUrl: string

  constructor() {
    super(OrganizationPlanController.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async get(
    organizationId: string,
  ): TResponseBase<NOrganizationPlans.IOrganizationPlan> {
    return await useFetchInstance(`${this.serviceUrl}/${organizationId}`, {
      method: 'GET',
    })
  }

  async getOrganizationPlanOverview(
    organizationId: string,
  ): TResponseBase<NGetOrganizationPlanOverview.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/overview`,
      {
        method: 'GET',
      },
    )
  }

  async cancelOrganizationPlan(
    organizationId: string,
    body: NCancelOrganizationPlan.IBody,
  ): TResponseBase<NCancelOrganizationPlan.TResponseData> {
    return await useFetchInstance(`${this.serviceUrl}/${organizationId}`, {
      method: 'DELETE',
      body,
    })
  }
}
